<template>
  <div
    class="flex flex-wrap items-center justify-between gap-y-4
    border-b border-zinc-200 bg-white p-4 sm:sticky sm:z-30 print:static print:w-full"
    :class="topStyle"
  >
    <div class="flex flex-wrap gap-2 lg:w-1/2 lg:flex-nowrap">
      <HubButton
        :label="dateButtonTitle"
        icon="i-mdi-calendar-month-outline"
        icon-location="right"
        size="xs"
        @click="toggleDateFilterModal()"
      />
      <span class="print:hidden">
        <HubButton
          :label="touchpointButtonTitle"
          icon="i-mdi-chevron-down"
          icon-location="right"
          size="xs"
          :disabled="hasSingleTouchpoint"
          @click="toggleTouchpointFilterModal()"
        />
      </span>
      <HubButton
        :label="outletButtonTitle"
        icon="i-mdi-chevron-down"
        icon-location="right"
        size="xs"
        @click="toggleOutletFilterModal()"
      />
      <HubDropdown
        class="lg:hidden"
        :items="[
          [{
            label: t('filters.quickFilters'),
            icon: 'i-mdi-sparkles-outline',
            hidden: !canReadQuickFilters,
            iconColor: 'trublue',
            click: openQuickFiltersModal,
          }],
          [{
            label: $t('filters.reset'),
            icon: 'i-mdi-replay',
            iconColor: 'trublue',
            click: resetFilters,
          }],
          [{
            label: $t('filters.print'),
            icon: 'i-mdi-printer-outline',
            iconColor: 'trublue',
            click: printPage,
          }],
        ]"
        :ui="{
          width: 'min-w-32',
        }"
      >
        <HubButton
          :label="$t('more')"
          icon="i-mdi-chevron-down"
          icon-location="right"
          size="xs"
          type="outlined"
        />
      </HubDropdown>
    </div>
    <div class="hidden w-1/2 items-center justify-end gap-2 lg:flex print:hidden">
      <HubButton
        v-if="canReadQuickFilters"
        :label="$t('filters.quickFilters')"
        icon="i-mdi-sparkles-outline"
        size="xs"
        type="outlined"
        @click="openQuickFiltersModal"
      />
      <HubButton
        :label="$t('filters.reset')"
        icon="i-mdi-replay"
        size="xs"
        type="outlined"
        @click="resetFilters"
      />
      <UIcon
        name="i-mdi-printer-outline"
        class="ml-2 !size-6 cursor-pointer text-trublue"
        :title="$t('filters.print')"
        @click="printPage"
      />
    </div>

    <DashboardFilterTouchpointModal
      v-model="showTouchpointFilterModal"
      v-model:selected-touchpoints="selectedTouchpoints"
      v-model:selected-touchpoint-names="selectedTouchpointNames"
      :available-touchpoints="availableTouchpoints"
      @update:selected-touchpoints="updateFilter('touchpointIds', $event)"
    />

    <DashboardFilterOutletModal
      v-model="showOutletFilterModal"
      v-model:selected-outlets="selectedOutlets"
      v-model:total-selected-outlets-count="selectedOutletCount"
      :organisation-id="organisationId"
      @update:selected-outlets="updateFilter('organisationNodeIds', $event)"
    />

    <DashboardFilterDateModal
      v-model="showDateFilterModal"
      :date-from="(params.dateFrom as string)"
      :date-to="(params.dateTo as string)"
      :relative-date="(params.relativeDate as string)"
      :confirm-function="updateDateFilters"
    />

    <LazyDashboardFilterQuickModal
      v-if="canReadQuickFilters"
      v-model="showQuickFilterModal"
      :root-organisation-node-id="organisationId"
      :selected-touchpoints="selectedTouchpoints"
      :selected-outlets="selectedOutlets"
      :relative-date="(params.relativeDate as string)"
      :date-from="(params.dateFrom as string)"
      :date-to="(params.dateTo as string)"
      :quick-filters="organisationQuickFilters"
      :refetch-data="fetchData"
      :available-touchpoints="availableTouchpoints"
      :total-selected-outlets-count="selectedOutletCount"
      @update:apply-filter="updateQuickFilters($event)"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import type { HubFilter, OrganisationQuickFilter } from '~/types'

const { t } = useI18n()
const route = useRoute()

// props
const props = defineProps<{ dashboardId?: string, organisationId: number }>()

// internal refs

// -- toggles
const [showQuickFilterModal, toggleQuickFilterModal] = useToggle()
const [showDateFilterModal, toggleDateFilterModal] = useToggle()
const [showOutletFilterModal, toggleOutletFilterModal] = useToggle()
const [showTouchpointFilterModal, toggleTouchpointFilterModal] = useToggle()

// --stores
const { fetchImpersonatorName } = useUserStore()
const touchpointStore = useTouchpointStore()
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { currentOrganisationTouchpoints } = storeToRefs(touchpointStore)

if (!currentOrganisationTouchpoints.value) {
  await touchpointStore.fetchTouchpointForOrganisation(props.organisationId)
}

// touchpoint computed because these are needed prior to the other variables
const availableTouchpoints = computed(() => currentOrganisationTouchpoints.value
  .filter(touchpoint => {
    if (touchpoint.label === 'inStore' && route.path.includes('comment')) return false
    return true
  })
  .map(touchpoint => ({
    key: touchpoint.id,
    label: touchpoint.translateLabel ? t('dashboards.touchpoint.name.' + touchpoint.label) : touchpoint.label,
    touchpointObject: touchpoint
  })))

// local refs continued
const impersonatorName = fetchImpersonatorName()
const topStyle = impersonatorName ? 'sm:top-[85px]' : 'sm:top-12 print:top-0'
const initialValueSet = ref(false)
const selectedOutletCount = ref(0)
const selectedOutlets = ref<Array<string>>()
const organisationQuickFilters = ref<OrganisationQuickFilter[]>([])

// computed
const hasSingleTouchpoint: ComputedRef<boolean> = computed(() => availableTouchpoints.value.length === 1)
const selectedTouchpointNames = computed(() => hasSingleTouchpoint.value
  ? [availableTouchpoints.value[0].label]
  : undefined)
const selectedTouchpoints = computed(() => hasSingleTouchpoint.value
  ? [availableTouchpoints.value[0].key]
  : undefined)
const dateButtonTitle: ComputedRef<string> = computed(() => {
  if (params.value.dateFrom && params.value.dateTo) {
    return t('reports.heatmap.timeFromTo', {
      minValue: dayjs(params.value.dateFrom).format('DD MMM YYYY'),
      maxValue: dayjs(params.value.dateTo).format('DD MMM YYYY')
    })
  }

  if (params.value.relativeDate) {
    const humanTranslation = getHumanRelativeDateTranslationString(params.value.relativeDate) as [{
      string: string
      translationRequired: boolean
      options?: {
        [key: string]: string | number
      }
    }]

    // @ts-expect-error this works and is fine
    return humanTranslation.map(x => (x.translationRequired ? t(x.string, x?.options) : x.string)).join(' ')
  }

  return t('filters.dateFilter')
})
const outletButtonTitle: ComputedRef<string> = computed(() => {
  if (selectedOutletCount.value) {
    return selectedOutletCount.value.toString() + ' ' + t('organisations.outlet', selectedOutletCount.value)
  }

  return t('organisations.outlet', 2)
})
const touchpointButtonTitle: ComputedRef<string> = computed(() => {
  if (!selectedTouchpointNames.value || !selectedTouchpointNames.value.length) {
    return t('organisations.touchpoint', 2)
  }

  return getArrayAsText(selectedTouchpointNames.value)
})
const canReadQuickFilters = computed(() =>
  checkPermissions({
    permissions: ['QuickFilters.Self.Read', 'QuickFilters.Organisation.Read'],
    allRequired: false
  })
)

// functions
async function openQuickFiltersModal() {
  await fetchData()
  toggleQuickFilterModal()
}

function printPage() {
  if (window) {
    window.print()
  }
}

function resetFilters() {
  filterStore.$reset()
}

async function fetchData() {
  const filterData = (await $hubFetch(
    `/api/v4/organisations/${props.organisationId}/filters`
  )) as OrganisationQuickFilter[]

  organisationQuickFilters.value = filterData
}

async function updateDateFilters(filter: HubFilter) {
  // set query params
  params.value.relativeDate = filter.relativeDate
  params.value.dateFrom = filter.dateFrom
  params.value.dateTo = filter.dateTo
}
async function getUsersFilters() {
  if (!props.dashboardId) {
    return
  }

  if (objectIsEmpty(params.value)) {
    const data = (await $hubFetch(`/api/v4/dashboards/${props.dashboardId}/filters/user`)) as { config: HubFilter }

    if (data && data.config) {
      // update query params to users last filters

      params.value.relativeDate = data.config.relativeDate
      params.value.dateFrom = data.config.dateFrom
      params.value.dateTo = data.config.dateTo
      params.value.touchpointIds = data.config.touchpointIds
      params.value.organisationNodeIds = data.config.organisationNodeIds
    }
  }

  initialValueSet.value = true
}

const updateQuickFilters = async (filter: OrganisationQuickFilter) => {
  delete filter.config.totalOutlets

  params.value.relativeDate = filter.config.relativeDate
  params.value.dateFrom = filter.config.dateFrom
  params.value.dateTo = filter.config.dateTo
  params.value.touchpointIds = filter.config.touchpointIds
  params.value.organisationNodeIds = filter.config.organisationNodeIds

  await updateDateFilters(filter.config)

  selectedOutlets.value = filter.config.organisationNodeIds
  selectedTouchpoints.value = filter.config.touchpointIds
}

// debounced functions
const saveUserFilters = debounce(async () => {
  if (!initialValueSet.value || !props.dashboardId) {
    return
  }

  await $hubFetch(`/api/v4/dashboards/${props.dashboardId}/filters/user`, {
    method: 'post',
    body: {
      config: {
        ...params.value
      }
    }
  })
}, 1000)

const updateFilter = debounce(async (filter, filterValue) => {
  if (!filter) {
    return
  }

  // update query params to include the filters
  params.value[filter] = filterValue
}, 750)

// watchers
watch(
  () => params,
  () => saveUserFilters(),
  { deep: true }
)

watch(
  () => params,
  () => {
    selectedOutlets.value = params.value.organisationNodeIds
      ? Array.isArray(params.value.organisationNodeIds)
        ? (params.value.organisationNodeIds as string[])
        : [params.value.organisationNodeIds as string]
      : undefined

    if (!hasSingleTouchpoint.value) {
      selectedTouchpoints.value = params.value.touchpointIds as Array<string>
    }
  },
  { deep: true, immediate: true }
)

await getUsersFilters()
</script>
